<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout
    },
  },
}
</script>

<style lang="scss">
// fix for 100vh IOS
body.fullHeightIOS {
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
}
// hide google translate bar
.skiptranslate {
  display: none;
  height: 0;
}
.VIpgJd-yAWNEb-hvhgNd {
  display: none;
}
.VIpgJd-suEOdc {
  display: none!important;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

body {
  top: 0 !important;
}
</style>
